import BaseService from '@/services/BaseService'

class ShipmentRequestService extends BaseService {
  constructor() {
    super()
  }

  // Shipment List Index
  getListShipmentCourier(opts = {}) {
    this.endPoint = 'api/v2/shipments'
    return this.get(opts).then(response => {
      return response
    })
  }

  getPackingList(opts = {}) {
    this.endPoint = 'api/v2/package'
    return this.get(opts).then(response => {
      return response
    })
  }

  getPacking(opts = {}) {
    this.endPoint = 'api/v2/packing-list'
    return this.get(opts).then(response => {
      return response
    })
  }
  
  // Shipment Request Index
  getShipmentRequestIndex(opts = {}) {
    this.endPoint = 'shipment-requests'
    return this.get(opts).then(response => {
      return response.data
    })
  }

  deleteShipmentRequest(id: string | number, opts = {}) {
    this.endPoint = 'shipment-requests'
    return this.delete(id, opts).then(response => {
      return response
    })
  }

  // Shipment Request Detail
  getShipmentRequestDetail(id: string | number, opts = {}) {
    this.endPoint = `shipment-requests/${id}`
    return this.get(opts).then(response => {
      return response.data
    })
  }

  updateShipmentRequestDetail(id: string | number, data: object, opts = {}) {
    this.endPoint = `shipment-requests/${id}`
    return this.put(data, opts).then(response => {
      return response.data
    })
  }

  // Shipment Request Conversation
  getShipmentRequestConversation(id: string | number, opts = {}) {
    this.endPoint = `shipment-requests/${id}/conversations`
    return this.get(opts).then(response => {
      return response.data
    })
  }

  sendMessage(id: string | number, data: object, opts = {}) {
    this.endPoint = `shipment-requests/${id}/conversations`
    return this.post(data, opts).then(response => {
      return response.data
    })
  }

  // Shipment Request Attachment
  deleteAttachment(id: string | number, opts = {}) {
    this.endPoint = 'shipment-request-files'
    return this.delete(id, opts).then(response => {
      return response
    })
  }
  changeType(id: string | number, data: object, opts = {}) {
    this.endPoint = `shipment-request-files/${id}`
    return this.put(data, opts).then(response => {
      return response
    })
  }
  // Shipment Request Response
  saveResponse(data: object, opts = {}) {
    this.endPoint = 'shipments'
    return this.post(data, opts).then(response => {
      return response
    })
  }
  changeStatus(id: string | number, data: object, opts = {}) {
    this.endPoint = `shipment-requests/${id}`
    return this.put(data, opts).then(response => {
      return response
    })
  }

  // Shipment Request Quotes
  getShipmentRequestQuotes(id: string | number, opts = {}) {
    this.endPoint = `shipment-requests/${id}`
    return this.get(opts).then(response => {
      return response.data
    })
  }

  saveQuote(data: object, opts = {}) {
    this.endPoint = 'shipment-quotes'
    return this.post(data, opts).then(response => {
      return response
    })
  }
}

export default new ShipmentRequestService()
