// node_modules dependencies
import Component from 'vue-class-component'
import { Watch } from 'vue-property-decorator'

// Lodash functions. Importing one-by-one reduce significant bundle size rather than import whole module
import forEach from 'lodash/forEach'
import upperCase from 'lodash/upperCase'
import replace from 'lodash/replace'
import startCase from 'lodash/startCase'
import isEmpty from 'lodash/isEmpty'

// Base Page
import BasePage from '@/pages/Base'

// Config
import config from '@/configs'

// Services
import ShipmentRequestService from '@/services/Shipments/ShipmentRequest'

@Component({})
export default class ShipmentRequestIndex extends BasePage {
  constructor() {
    super()
  }

  // BaseURL
  baseUrl = config.api.baseURL

  // Table
  tableHeaders: object[] = [
    {
      text: 'Package Shipment ID',
      align: 'center',
      sortable: false,
      value: 'packageID'
    },
    {
      text: 'Order ID',
      align: 'center',
      sortable: false,
      value: 'orderID'
    },
    {
      text: 'Courier Name',
      align: 'center',
      sortable: false,
      value: 'courierName'
    },
    {
      text: 'Tracking Code',
      align: 'center',
      sortable: false,
      value: 'trackingCode'
    },
    {
      text: 'Customer Name',
      align: 'center',
      sortable: false,
      value: 'customerName'
    },
    {
      text: 'Gross Weight',
      align: 'center',
      sortable: false,
      value: 'grossWeight'
    },
    {
      text: 'Action',
      align: 'center',
      sortable: false,
      value: 'action',
      class: 'action-table-width'
    }
  ]
  tableItems: object[] = []
  tableTotalItems: string | number = 0
  tableRowsPerPageItems: number[] = [5, 10, 15, 25, 50, 100]
  tablePagination: any = {
    sortBy: '-created_at',
    page: 1,
    rowsPerPage: 25,
    descending: true,
    totalItems: 0
  }
  tableLoading: boolean = true
  searchInput: string = ''
  searchTrackingCode: string = ''
  searchCustomerName: string = ''

  // Delete Shipment Request
  deleteDialog: boolean = false

  mounted() {
    this.getShipmentRequestList()
  }

  @Watch('searchInput')
  @Watch('searchTrackingCode')
  @Watch('searchCustomerName')
  @Watch('tablePagination', { deep: true })
  onTablePaginationChanged() {
    this.$vuetify.goTo(0)
    this.getShipmentRequestList()
  }

  async getShipmentRequestList(){
    const opts: any = {
      params: {
        'page[num]': this.tablePagination.page,
        'page[limit]': this.tablePagination.rowsPerPage,
        'sort': this.tablePagination.sortBy,
        'filter[courier_name][not]': 'acn',
        'include': 'packaging,order'
      }
    }
    if (this.searchInput != '') {
      opts.params = {
        ...opts.params,
        'filter[id][like]': this.searchInput
      }
    } else {
      var textFile = 'filter[id][like]'
      delete opts.params[textFile]
    }

    if (this.searchTrackingCode != '') {
      opts.params = {
        ...opts.params,
        'filter[tracking_code][like]': this.searchTrackingCode
      }
    } else {
      var textFile = 'filter[tracking_code][like]'
      delete opts.params[textFile]
    }

    if (this.searchCustomerName != '') {
      opts.params = {
        ...opts.params,
        'filter[order.customer_name][like]': this.searchCustomerName
      }
    } else {
      var textFile = 'filter[order.customer_name][like]'
      delete opts.params[textFile]
    }

    this.tableLoading = true
    await ShipmentRequestService.getPackingList(opts)
      .then(response => {
        const responseData = response.data.data
        const responseDataIncluded = response.data.included
        const responseMeta = response.data.meta
        this.tableItems = []

        forEach(responseData, dataPackageShipment => {
          let shipment = {
            packageID: dataPackageShipment.attributes.id,
            orderID: responseDataIncluded.order[dataPackageShipment.attributes.order_id].attributes.order_code,
            trackingCode: dataPackageShipment.attributes.tracking_code,
            courierName: dataPackageShipment.attributes.courier_name,
            linkTracking: '',
            linkOrder: '',
            customerName: '',
            grossWeight: 0
          }

          if (this.baseUrl === 'https://api2dev.asiacommerce.net/') {
            shipment.linkTracking = 'https://alidev.asiacommerce.net/tracking/' + shipment.trackingCode
            shipment.linkOrder = 'https://managedev.asiacommerce.net/orders/' + dataPackageShipment.attributes.order_id + '/details'
          }
          else if (this.baseUrl === 'https://api2.asiacommerce.net/'){
            shipment.linkTracking = 'https://ali.asiacommerce.net/tracking/' + shipment.trackingCode
            shipment.linkOrder = 'https://manage.asiacommerce.net/orders/' + dataPackageShipment.attributes.order_id + '/details'
          }

          if(!isEmpty(dataPackageShipment.relationships.packaging)){
            for (const item_packaging of dataPackageShipment.relationships.packaging){
              shipment.grossWeight += Number(responseDataIncluded.packaging[Number(item_packaging.id)].attributes.gross_weight)
            }
          }

          if(!isEmpty(dataPackageShipment.relationships.order)){
            shipment.customerName = responseDataIncluded.order[Number(dataPackageShipment.relationships.order.id)].attributes.customer_name
          }

          this.tableItems.push(shipment)
        })

        this.tableTotalItems = responseMeta.pagination.total
        this.tableLoading = false
      })
      .catch(error => {
        this.tableLoading = false
        this.catchHandler(error)
      })
  }

  deleteShipmentRequest(props: any) {
    props.item.deleteDialog = false
    this.showLoading({ text: 'Deleting Shipment Request...' })
    ShipmentRequestService.deleteShipmentRequest(props.item.id)
      .then(response => {
        this.closeLoading()
        this.showSnackbar(`${startCase(response.data.message)}`, 'green', 1500)
        this.$vuetify.goTo(0)
        this.getShipmentRequestList()
      })
      .catch(error => this.catchHandler(error))
  }

  searchFilterChanged(newData: any) {
    this.tablePagination.page = 1
    this.searchFilterParams = newData
    this.getShipmentRequestList()
  }
}
